/** @jsx jsx */
import Banner from "../components/Banner"
import { Text, Grid, Box, Heading, Styled, jsx } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Slide } from "react-awesome-reveal"

interface BlogHeaderProps {
  children: React.ReactNode
}

const BlogHeader = (props: BlogHeaderProps) => {
  return (
    <Slide triggerOnce={true} direction="down" duration={500}>
      <Heading mb={2} sx={{ fontSize: [5, 6], textTransform: "capitalize" }}>
        {props.children}
      </Heading>
    </Slide>
  )
}

interface BlogBodyProps {
  children: React.ReactNode
}

const BlogBody = (props: BlogBodyProps) => {
  return (
    <Slide triggerOnce={true} direction="right" duration={500}>
      <Text variant="default" mt={4} sx={{ lineHeight: 2.5 }}>
        {props.children}
      </Text>
    </Slide>
  )
}

interface BlogGridProps {
  children: React.ReactNode
  columns: string
}

const BlogGrid = (props: BlogGridProps) => {
  return (
    <Grid columns={[1, null, null, null, props.columns]} gap={4} my={5}>
      {props.children}
    </Grid>
  )
}

const ReadMoreLink = () => {
  return (
    <Box sx={{ textAlign: "right", fontSize: 3 }} mt={2}>
      <Styled.a>Read more...</Styled.a>
    </Box>
  )
}

export const fluidImage = graphql`
  fragment blogFluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
export const query = graphql`
  query {
    mobileBanner: file(relativePath: { eq: "blogMobileBanner.png" }) {
      ...blogFluidImage
    }
    desktopBanner: file(relativePath: { eq: "blogDesktopBanner.png" }) {
      ...blogFluidImage
    }
    blogPicture1: file(relativePath: { eq: "blogPicture1.png" }) {
      ...blogFluidImage
    }
    blogPicture2: file(relativePath: { eq: "blogPicture2.png" }) {
      ...blogFluidImage
    }
  }
`

export default function Blog({ data }) {
  return (
    <Layout>
      <SEO title="Blog" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Blog Speak"
        alt="blog"
      />
      <Box m={[3, null, null, 5]}>
        <BlogGrid columns="3fr 2fr">
          <Box bg="highlight6" p={4} sx={{ textAlign: "center" }}>
            <BlogHeader>
              The World is a canvas and these women are paiting it green with
              their nature-made art
            </BlogHeader>
            <Text variant="secondary" color="accent2">
              By Admin | Aug 18 2019 | Green Homes, Terrariums
            </Text>
            <BlogBody>
              We all speak of bygone days when we had more trees, large
              playgrounds, open gardens and a lot of greenery to revel in. As
              the world becomes more concrete and swathes of green keep
              vanishing from our lives, and the dangers of global warming rise,
              it’s time to rethink…
            </BlogBody>
            <ReadMoreLink />
          </Box>
          <Img
            sx={{ order: ["-1", null, null, null, "0"] }}
            fluid={data.blogPicture1.childImageSharp.fluid}
            alt="The world is a canvas"
          />
        </BlogGrid>
        <BlogGrid columns="2fr 3fr">
          <Img
            fluid={data.blogPicture2.childImageSharp.fluid}
            alt="Valentine's Day"
          />
          <Box bg="highlight6" p={4} sx={{ textAlign: "center" }}>
            <BlogHeader>
              This Valentine’s Day, Discover The True Meaning Of Love
            </BlogHeader>
            <Text variant="secondary" color="accent2">
              By Admin | Aug 18 2019 | Green Homes, Terrariums
            </Text>
            <BlogBody>
              We all speak of bygone days when we had more trees, large
              playgrounds, open gardens and a lot of greenery to revel in. As
              the world becomes more concrete and swathes of green keep
              vanishing from our lives, and the dangers of global warming rise,
              it’s time to rethink…
            </BlogBody>
            <ReadMoreLink />
          </Box>
        </BlogGrid>
      </Box>
    </Layout>
  )
}
